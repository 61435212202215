// FADE TO TOP
var $animation_elements = $('.animated');
var $window = $(window);

function check_if_in_view() {
    var window_height = $window.height();
    var window_top_position = $window.scrollTop();
    var window_bottom_position = (window_top_position + window_height);

    $.each($animation_elements, function() {
        var $element = $(this);
        var element_height = $element.outerHeight();
        var element_top_position = $element.offset().top;
        var element_bottom_position = (element_top_position + element_height);

        //check to see if this current container is within viewport
        if ((element_bottom_position >= window_top_position) &&
            (element_top_position <= window_bottom_position)) {
            $element.addClass('active');
        }
        //else {
        //$element.removeClass('active');
        //}
    });
}

$window.on('scroll resize', check_if_in_view);
$window.trigger('scroll');

(function() {

    var s = {

        menuState: function() {

            $(this).toggleClass("cross");
            $("body").toggleClass("open");

        },

        headerHeight: function() {

            if ($(window).scrollTop() > 0) {

                return $('.main-header').height();
            } else {

                return $('.main-header').height() - 24;
            }
        },

        headerPosition: function() {

            var top = $(window).scrollTop();

            if (top > 300) {

                $(".main-header").addClass("min");
                $(".sub-nav").addClass("min");
            } else {

                $(".main-header").removeClass("min");
                $(".sub-nav").removeClass("min");
            }
        },

        events: function() {
            $(window).scroll(this.headerPosition);
            $("#btnMobile").on("click", this.menuState);
        },

        init: function() {

            this.headerPosition();
            this.events();
        }

    };

    s.init();

})();

// SCROLL TO ANCHOR PRODUCT
$('a[href^="#"]').on('click',function (e) {
    console.log('link-#');
    e.preventDefault();

    var target = this.hash,
    $target = $(target);

    $('html, body').stop().animate({
        'scrollTop': $target.offset().top-140
    }, 1200, 'swing', function () {
        //window.location.hash = target;
    });
});

// Slideshow
$(document).ready(function(){
  $('.slideshow > .slider-wrapper').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
            }
        }
    ],
  });
});

// Actualités
$(document).ready(function(){

    $('.news-grid-slider > .content').slick({
        slidesToShow: 2,
        slidesToScroll: 2,
        arrows: false,
        dots: true,
        // adaptiveHeight: true,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: false,
                arrows: true,
                // adaptiveHeight: true,
                }
            },
        ],
    })

});
